//Libraries
import React, { useContext, useEffect, useState } from "react";
import { User } from "firebase/auth";
//@ts-ignore
import TawkTo from "tawkto-react";

//Components
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { withSuspense } from "./containers/appRouter";

//Context
import { FirebaseContext } from "./context/firebaseContextProvider";
import { Loader } from "./components/loader";

const NeedsEmailVerification = React.lazy(() => import("./containers/auth/needsEmailVerification"));
const Layout = React.lazy(() => import("./containers/layout"));
const Login = React.lazy(() => import("./containers/auth/login"));
const Registration = React.lazy(() => import("./containers/auth/registration"));
const ResetPassword = React.lazy(() => import("./containers/auth/resetPassword"));

type ProtectedRouteProps = {
  exact?: boolean;
  path: string;
  component: React.FunctionComponent;
  user: User | null | undefined;
};

/** Redirect to login page if user tries to visit while not authenticated */
function ProtectedRoute(props: ProtectedRouteProps) {
  if (typeof props.user === "undefined") {
    return <Loader />;
  } else if (props.user === null) {
    return <Redirect to="/login" />;
  // } else if (!props.user.emailVerified) {
  //   return (
  //     <Route
  //       exact={props.exact}
  //       component={withSuspense(NeedsEmailVerification)}
  //       path={props.path}
  //     />
  //   );
  } else {
    return <Route exact={props.exact} component={props.component} path={props.path} />;
  }
}

/** Redirect to dashboard if user tries to visit while authenticated */
function LoggedInRoute(props: ProtectedRouteProps) {
  return !props.user ? (
    <Route exact={props.exact} component={props.component} path={props.path} />
  ) : (
    <Redirect to="/" />
  );
}

function App() {
  const firebaseContext = useContext(FirebaseContext);

  const [tawk, setTawk] = useState<any>();

  useEffect(() => {
    console.log("tawk effect run");
    if (firebaseContext?.userData && firebaseContext.userData.exists() && !tawk) {
      console.log("setting tawk");
      const LT_TAWK_ID = "1frqpemci";
      const EN_TAWK_ID = "1frqnh5ld";

      const languageSelected = firebaseContext.userData.data().language;

      if (languageSelected === "en") {
        const tawkInstance = new TawkTo("620997b3b9e4e21181beea73", EN_TAWK_ID);
        tawkInstance.onLoad(() => {
          //@ts-ignore
          window.Tawk_API.setAttributes({
            name:
              firebaseContext?.userData?.data()?.firstName +
              " " +
              firebaseContext?.userData?.data()?.lastName,
            email: firebaseContext?.userData?.data()?.email,
            id: firebaseContext?.userData?.id,
            language: firebaseContext?.userData?.data()?.language,
          });
        });
        setTawk(tawkInstance);
      } else if (languageSelected === "lt") {
        const tawkInstance = new TawkTo("620997b3b9e4e21181beea73", LT_TAWK_ID);
        tawkInstance.onLoad(() => {
          //@ts-ignore
          window.Tawk_API.setAttributes({
            name:
              firebaseContext?.userData?.data()?.firstName +
              " " +
              firebaseContext?.userData?.data()?.lastName,
            email: firebaseContext?.userData?.data()?.email,
            id: firebaseContext?.userData?.id,
            language: firebaseContext?.userData?.data()?.language,
          });
        });
        setTawk(tawkInstance);
      } else {
        const exhaustive: never = languageSelected;
      }
    }

    if (
      //@ts-ignore
      firebaseContext &&
      firebaseContext.userData &&
      firebaseContext.userData.exists() &&
      tawk
    ) {
      console.log("setting attributes");
      console.log({
        name:
          firebaseContext.userData.data().firstName +
          " " +
          firebaseContext.userData.data().lastName,
        email: firebaseContext.userData.data().email,
        id: firebaseContext.userData.id,
        language: firebaseContext.userData.data().language,
      });
      tawk.setAttributes(
        {
          name:
            firebaseContext.userData.data().firstName +
            " " +
            firebaseContext.userData.data().lastName,
          email: firebaseContext.userData.data().email,
          id: firebaseContext.userData.id,
          language: firebaseContext.userData.data().language,
        },
        function (error: any) {
          console.error(error);
        }
      );
    }
    //@ts-ignore
  }, [firebaseContext?.userData, tawk]);

  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <LoggedInRoute
            exact
            path="/login"
            component={withSuspense(Login)}
            user={firebaseContext?.user}
          />
          <LoggedInRoute
            exact
            path="/register"
            component={withSuspense(Registration)}
            user={firebaseContext?.user}
          />
          <Route exact path="/resetpassword" component={withSuspense(ResetPassword)} />
          <ProtectedRoute path="/" component={withSuspense(Layout)} user={firebaseContext?.user} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
