//Libraries
import React from "react";
import i18n from "./locale";

//Context
import { I18nextProvider } from "react-i18next";
import { ClientContextProvider } from "./context/contextProvider";
import FirebaseProvider from "./context/firebaseContextProvider";
import { RightSidebarProvider } from "./context/rightSidebarProvider";
import { EventsProvider } from "./context/eventsContext";
import { UnreadNotificationsProvider } from "./context/inboxContext";

//Components
import ErrorBoundary from "./containers/errorBoundary";

export const Wrapper: React.FC<{}> = ({ children }) => {
  return (
    <FirebaseProvider>
      <ClientContextProvider>
        <RightSidebarProvider>
          <I18nextProvider i18n={i18n}>
            <EventsProvider>
              <UnreadNotificationsProvider>
                <ErrorBoundary>{children}</ErrorBoundary>
              </UnreadNotificationsProvider>
            </EventsProvider>
          </I18nextProvider>
        </RightSidebarProvider>
      </ClientContextProvider>
    </FirebaseProvider>
  );
};
