//Libraries
import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  DocumentData,
  PartialWithFieldValue,
  WithFieldValue,
} from "firebase/firestore";
import * as fs from "firebase/firestore";

//Helpers
import { hashSectionVersion } from "../../../containers/events/eventHelpers";

//Types
import {
  EventHistoryItemComment,
  EventHistoryItemUser,
  EventModel,
  EventModelFirebase,
  EventRole,
  EventSection,
  EventSectionVersion,
  EventSignature,
  EventSignOff,
} from "./model";
import ProcedureClass from "../procedure/class";
import db, { CompleteModel, ModelClass } from "../../models";
import { ProcedureSection } from "../procedure/model";
import { TeamModel } from "../team/model";
import { UserModel } from "../user/model";
import hash from "object-hash";

interface EventClass extends ModelClass<EventModel> {}
class EventClass {
  constructor(eventParams: EventModel) {
    this.projectId = eventParams.projectId;
    this.procedureId = eventParams.procedureId;
    this.status = eventParams.status;
    this.history = eventParams.history;
    this.roles = eventParams.roles;
    this.sections = eventParams.sections;
    this.signOffs = eventParams.signOffs;
    this.publicId = eventParams.publicId;
    this.viewers = eventParams.viewers;
    this.archivedFor = eventParams.archivedFor;
  }

  isComplete(): this is CompleteModel<EventClass> {
    return !!(
      this.projectId &&
      this.procedureId &&
      this.status &&
      this.history &&
      this.roles &&
      this.sections
    );
  }

  get(): EventModelFirebase {
    if (!this.isComplete()) {
      if (process.env.NODE_ENV === "development") {
        console.warn({
          history: this.history,
          procedureId: this.procedureId,
          projectId: this.projectId,
          roles: this.roles,
          sections: this.sections,
          status: this.status,
        });
      }
      throw new Error("Event is incomplete");
    }

    const teamIds: string[] = [];
    Object.values(this.roles).forEach((role) => {
      teamIds.push(...role.teamIds);
    });

    const teamIdsNoDuplicates = teamIds.filter((id, i) => teamIds.indexOf(id) == i);

    return {
      history: this.history,
      procedureId: this.procedureId,
      projectId: this.projectId,
      roles: this.roles,
      sections: this.sections,
      status: this.status,
      signOffs: this.signOffs,
      publicId: this.publicId,
      viewers: this.viewers || [],
      archivedFor: this.archivedFor,
      _ownerTeams: teamIdsNoDuplicates,
    };
  }

  async getHistoryUsers() {
    if (!this.isComplete()) {
      return [];
    }

    const userIdsToGet: string[] = [];

    this.sections.forEach((section) => {
      if (!userIdsToGet.includes(section.currentVersion.createdBy)) {
        userIdsToGet.push(section.currentVersion.createdBy);
      }

      section.oldVersions.forEach((oldVersion) => {
        if (!userIdsToGet.includes(oldVersion.createdBy)) {
          userIdsToGet.push(oldVersion.createdBy);
        }
      });
    });

    const users = Promise.all(
      userIdsToGet.map((id) => {
        return fs.getDoc(fs.doc(db.users, id));
      })
    );

    return users;
  }

  /**
   * Returns an object where object keys correspond to role names, and values to the maximum number of signatures
   * needed
   */
  getRequiredSignOffs(procedureData: ProcedureClass): { [key: string]: number } {
    if (!this.isComplete()) {
      return {};
    }

    if (!procedureData) {
      throw new Error(`Procedure does not exist`);
    }

    const currentState = procedureData.states[this.status];

    const requiredSignOffs: { [key: string]: number } = {};

    //Count up the maximum number of required sign-offs to cover every action
    Object.entries(currentState.on).forEach(([actionName, action]) => {
      const requiredSignOffsLocalCounter: { [key: string]: number } = {};
      action.requiredSignOffs.forEach((role) => {
        requiredSignOffsLocalCounter[role] = requiredSignOffsLocalCounter[role]
          ? requiredSignOffsLocalCounter[role] + 1
          : 1;
      });

      Object.entries(requiredSignOffsLocalCounter).forEach(([roleName, signOffCount]) => {
        requiredSignOffs[roleName] = requiredSignOffs[roleName]
          ? requiredSignOffs[roleName] + 1
          : 1;
      });
    });

    return requiredSignOffs;
  }

  static validateSignature(
    signature: EventSignature,
    userData: UserModel
  ): { status: "success" | "fail"; message: string } {
    if (signature.type === "typed") {
      const split = signature.value.split(" ");
      if (split.length < 2) {
        return { status: "fail", message: "Doesn't have 2 words" };
      }

      if (split[0].length === 0 || split[1].length === 0) {
        return {
          status: "fail",
          message: "Words must have at least 1 character",
        };
      }

      if (signature.value !== userData.firstName + " " + userData.lastName) {
        return { status: "fail", message: "Name doesn't match" };
      }

      return { status: "success", message: "" };
    } else {
      const exhaustiveCheck: never = signature.type;
      throw new Error(`Unexpected signature type ${signature ? signature.type : signature}`);
    }
  }

  static createEventSection(
    procedureSection: ProcedureSection,
    userId: string,
    signature: EventSignature,
    userData: UserModel
  ): EventSection {
    const signatureValidatorResult = this.validateSignature(signature, userData);
    if (!signatureValidatorResult) {
      throw new Error(signatureValidatorResult);
    }

    const currentVersion: Omit<EventSectionVersion, "hash"> = {
      createdAt: fs.Timestamp.fromDate(new Date()),
      createdBy: userId,
      fields: procedureSection.fields,
      signature: signature,
    };

    return {
      name: procedureSection.name,
      currentVersion: {
        ...currentVersion,
        hash: hashSectionVersion(currentVersion),
      },
      oldVersions: [],
    };
  }

  /** Finds the event field with id "subject" and returns its value */
  getName(): string | undefined {
    let eventName: string | undefined;

    if (!this.isComplete()) {
      return;
    }

    this.sections.forEach((section) => {
      section.currentVersion.fields.forEach((field) => {
        if (field.id === "subject" && (field.type === "short_text" || field.type === "long_text")) {
          eventName = field.value;
        }
      });
    });

    return eventName;
  }

  async getProcedure(): Promise<fs.DocumentSnapshot<ProcedureClass>> {
    return fs.getDoc(fs.doc(db.procedures, this.procedureId));
  }

  /** Calculate the currency-independent monetary cost and time impact measured
   *  in days for the event
   */
  getCostImpact(): { cost: number; time: number } | undefined {
    let cost = 0;
    let time = 0;

    if (!this.isComplete()) {
      return;
    }

    this.sections.forEach((section) => {
      section.currentVersion.fields.forEach((field) => {
        if (field.type === "cost_impact") {
          field.value.forEach((impactItem) => {
            if (impactItem.type === "Addition") {
              const addition = parseFloat(impactItem.quantity) * parseFloat(impactItem.costPerUnit);
              cost += !isNaN(addition) ? addition : 0;
            } else if (impactItem.type === "Omission") {
              const subtraction =
                parseFloat(impactItem.quantity) * parseFloat(impactItem.costPerUnit);
              cost -= !isNaN(subtraction) ? subtraction : 0;
            }
          });
        } else if (field.type === "number" && field.containsProjectImpact === "cost") {
          cost += !isNaN(parseFloat(field.value)) ? parseFloat(field.value) : 0;
        } else if (field.type === "number" && field.containsProjectImpact === "time_days") {
          time += !isNaN(parseFloat(field.value)) ? parseFloat(field.value) : 0;
        }
      });
    });

    return { cost, time };
  }

  /**
   * Get an object of the teams and users who are action owners for the event in
   * its current state. Object keys are the action (eg. "Accept")
   */
  getActionOwners(
    procedureData: ProcedureClass,
    options?: {
      /** Only get action owners for actions where requiredAction is true */
      onlyRequiredActions?: boolean;
    }
  ): { [actionName: string]: EventRole } {
    if (!this.isComplete()) {
      throw new Error(`Action is not complete`);
    }

    const roles = this.roles;

    if (!procedureData) {
      throw new Error(`Procedure does not exist`);
    }

    const currentState = procedureData.states[this.status];

    if (!currentState) {
      throw new Error(`No procedure state found for event state ${this.status}`);
    }

    const actionOwners: { [actionName: string]: EventRole } = {};

    Object.entries(currentState.on).forEach(([actionName, action]) => {
      if (!action.requiredAction && options?.onlyRequiredActions) {
        return;
      }

      if (action.triggerType === "user") {
        if (typeof action.triggeredBy === "string") {
          actionOwners[actionName] = roles[action.triggeredBy];
        } else if (Array.isArray(action.triggeredBy)) {
          actionOwners[actionName] = action.triggeredBy.reduce(
            (store: EventRole, roleName) => {
              store.userIds.push(...roles[roleName].userIds);
              store.teamIds.push(...roles[roleName].teamIds);
              return {
                userIds: store.userIds,
                teamIds: store.teamIds,
              };
            },
            {
              userIds: [],
              teamIds: [],
            }
          );
        } else if (action.triggeredBy === null) {
          //Do nothing
        } else {
          const exhaustive: never = action.triggeredBy;
          throw new Error(`Strange triggeredBy for ${actionName}: "${action.triggeredBy}"`);
        }
      }
    });

    return actionOwners;
  }

  getTeams(): Promise<fs.DocumentSnapshot<TeamModel>>[] {
    if (!this.isComplete()) {
      return [];
    }

    const teamsList: string[] = Object.values(this.roles).reduce(
      (currentTeams: string[], team) => currentTeams.concat(...team.teamIds),
      []
    );

    return teamsList.map((teamId) => fs.getDoc(fs.doc(db.teams, teamId)));
  }

  /** Returns response time in MS */
  getRemainingResponseTime(procedureData: ProcedureClass): number | "none" | undefined {
    if (!this.isComplete()) {
      return;
    }

    if (!procedureData) {
      return;
    }

    const responseDuration = procedureData.states[this.status].responseDuration;

    if (responseDuration === "none") {
      return "none";
    } else if (typeof responseDuration !== "number") {
      throw new Error(`Expected response duration to be of type number, found ${responseDuration}`);
    }

    return (
      this.history[this.history.length - 1].timestamp.toDate().getTime() -
      new Date().getTime() +
      86400000 * responseDuration
    );
  }

  getResponseDuration(procedureData: ProcedureClass): number | "none" | undefined {
    if (!this.isComplete()) {
      return;
    }

    if (!procedureData) {
      return;
    }

    return procedureData.states[this.status].responseDuration;
  }
}

export const eventConverter: FirestoreDataConverter<EventClass> = {
  fromFirestore(
    snapshot: QueryDocumentSnapshot<EventModel>,
    options?: SnapshotOptions
  ): EventClass {
    const data = snapshot.data();

    return new EventClass({
      publicId: data.publicId,
      history: data.history,
      procedureId: data.procedureId,
      projectId: data.projectId,
      roles: data.roles,
      sections: data.sections,
      status: data.status,
      signOffs: data.signOffs,
      viewers: data.viewers,
      archivedFor: data.archivedFor,
    });
  },
  toFirestore(
    modelObject: WithFieldValue<EventClass> | PartialWithFieldValue<EventClass>
  ): DocumentData {
    if (modelObject instanceof EventClass) {
      return modelObject.get();
    } else {
      throw new Error("Expected an instance");
    }
  },
};

export default EventClass;
