//Libraries
import ReactDOM from "react-dom";

//Initialize firebase
import "./backend/init_firebase";

//Components
import App from "./App";

//Helpers
import reportWebVitals from "./reportWebVitals";

//CSS
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import { Wrapper } from "./Wrapper";

ReactDOM.render(
  <Wrapper>
    <App />
  </Wrapper>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
