//React
import React from "react";

//Components
import { Switch, Route, Redirect } from "react-router-dom";
import { Loader } from "../components/loader";
import ErrorBoundary from "./errorBoundary";

//Main Panels
const Home = React.lazy(() => import("../pages/home/HomePageRoot"));
const Projects = React.lazy(() => import("../containers/projects"));
const SingleProject = React.lazy(() => import("../pages/singleProject/SingleProjectRoot"));
const CreateProject = React.lazy(() => import("../containers/projects/createProject"));
const AddStakeholders = React.lazy(() => import("./stakeholders/addStakeholder"));
const Events = React.lazy(() => import("../pages/events/EventsPageRoot"));
const CreateEventChoice = React.lazy(() => import("../containers/events/createEventChoice"));
const CreateEvent = React.lazy(() => import("../containers/events/createEvent"));
const SingleEvent = React.lazy(() => import("../pages/singleEvent/SingleEventRoot"));
const SingleProcedure = React.lazy(() => import("../containers/projects/singleProcedure"));
const Stakeholders = React.lazy(() => import("../containers/stakeholders"));
const SingleStakeholder = React.lazy(() => import("./stakeholders/singleStakeholder"));
const AddStakeholder = React.lazy(() => import("../containers/stakeholders/addStakeholder"));
const CreateTeam = React.lazy(() => import("../containers/projects/createTeam"));
const SingleTeam = React.lazy(() => import("../containers/projects/singleTeam"));
const NeedsVerification = React.lazy(() => import("../containers/auth/needsEmailVerification"));
const Debug = React.lazy(() => import("../containers/debug"));
const Inbox = React.lazy(() => import("../containers/inbox"));
const Drafts = React.lazy(() => import("../containers/drafts"));
const Settings = React.lazy(() => import("../pages/settings/SettingsPageRoot"));

//Error
const FourZeroFour = React.lazy(() => import("./404"));

export function withSuspense(Component: React.FunctionComponent): React.FunctionComponent {
  return (props: any) => (
    <React.Suspense fallback={<Loader />}>
      <Component {...props} />
    </React.Suspense>
  );
}

function AppRouter() {
  return (
    <ErrorBoundary>
      <Switch>
        <Route exact path="/" component={withSuspense(Home)} />
        <Route
          exact
          path="/home"
          component={withSuspense(() => (
            <Redirect to={"/"} />
          ))}
        />
        <Route exact path="/settings" component={withSuspense(Settings)} />
        <Route exact path="/events" component={withSuspense(Events)} />
        <Route exact path="/events/:eventId" component={withSuspense(SingleEvent)} />
        <Route exact path="/projects" component={withSuspense(Projects)} />
        <Route exact path="/projects/createproject" component={withSuspense(CreateProject)} />
        <Route
          exact
          path="/projects/:projectId/addstakeholder"
          component={withSuspense(AddStakeholders)}
        />
        <Route exact path="/projects/:projectId/drafts" component={withSuspense(Drafts)} />
        <Route
          exact
          path="/projects/:projectId/events/create"
          component={withSuspense(CreateEventChoice)}
        />
        <Route
          exact
          path="/projects/:projectId/events/create/:procedureId"
          component={withSuspense(CreateEvent)}
        />
        <Route
          exact
          path="/projects/:projectId/procedures/:procedureId"
          component={withSuspense(SingleProcedure)}
        />
        <Route exact path="awaitingverification" component={withSuspense(NeedsVerification)} />
        <Route exact path="/projects/:projectId/addteam" component={withSuspense(CreateTeam)} />
        <Route
          exact
          path="/projects/:projectId/teams/:teamId"
          component={withSuspense(SingleTeam)}
        />
        <Route
          exact
          path="/projects/:projectId/stakeholders/:stakeholderId"
          component={withSuspense(SingleStakeholder)}
        />
        <Route
          exact
          path="/projects/:projectId/procedures"
          component={withSuspense((props: any) => (
            <Redirect to={"/projects/" + props.match.params.projectId + "/overview"} />
          ))}
        />
        <Route exact path="/projects/:projectId/:section" component={withSuspense(SingleProject)} />
        <Route
          path="/projects/:projectId"
          component={withSuspense((props: any) => (
            <Redirect to={"/projects/" + props.match.params.projectId + "/events"} />
          ))}
        />
        <Route exact path="/stakeholders" component={withSuspense(Stakeholders)} />
        <Route exact path="/stakeholders/addstakeholder" component={withSuspense(AddStakeholder)} />
        <Route
          exact
          path="/stakeholders/:stakeholderId"
          component={withSuspense(SingleStakeholder)}
        />
        <Route exact path="/debug" component={withSuspense(Debug)} />
        <Route exact path="/inbox" component={withSuspense(Inbox)} />
        {/* If no other paths match, show 404 */}
        <Route path="/" component={withSuspense(FourZeroFour)} />
      </Switch>
    </ErrorBoundary>
  );
}

export default AppRouter;
