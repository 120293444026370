//Libraries
import {
  FirestoreDataConverter,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SetOptions,
  SnapshotOptions,
  WithFieldValue,
} from "firebase/firestore";
//Types
import { EventField } from "../event/model";
import { CompleteModel } from "../../models";
import { ProcedureModel } from "./model";

interface ProcedureClass extends ProcedureModel {}
class ProcedureClass {
  constructor(procedureParams: ProcedureModel) {
    this.projectId = procedureParams.projectId;
    this.name = procedureParams.name;
    this.shorthand = procedureParams.shorthand;
    this.description = procedureParams.description;
    this.startingAction = procedureParams.startingAction;
    this.startingState = procedureParams.startingState;
    this.initiator = procedureParams.initiator;
    this.sections = procedureParams.sections;
    this.states = procedureParams.states;
    this.roles = procedureParams.roles;
    this.archived = procedureParams.archived;
  }

  isComplete(): this is CompleteModel<ProcedureClass> {
    return !!(
      this.projectId &&
      typeof this.name === "string" &&
      typeof this.shorthand === "string" &&
      typeof this.description === "string" &&
      this.startingAction &&
      this.startingState &&
      this.initiator &&
      this.sections &&
      this.states &&
      this.roles
    );
  }

  static validate(
    data: Partial<ProcedureModel>,
    options?: { debug?: boolean }
  ): data is ProcedureModel {
    const {
      projectId,
      name,
      shorthand,
      description,
      startingAction,
      startingState,
      initiator,
      sections,
      states,
      roles,
    } = data;

    if (
      !(
        projectId &&
        typeof name === "string" &&
        typeof shorthand === "string" &&
        typeof description === "string" &&
        startingAction &&
        startingState &&
        initiator &&
        sections &&
        states &&
        roles
      )
    ) {
      if (options?.debug) {
        throw new Error(`Missing parameters from ${JSON.stringify(data)}`);
      }
      return false;
    }

    let validated: boolean = true;

    //Fail if any state does not have an "on" property
    //Fail if any actions are triggered by roles that don't exist
    //Fail if any actions direct to states that don't exist
    Object.entries(states).forEach(([stateName, state]) => {
      if (!state?.on) {
        validated = false;

        if (options?.debug) {
          throw new Error(
            `The state "${stateName}" is undefined or does not have an "on" property`
          );
        }

        return validated;
      }

      Object.entries(state.on).forEach(([actionName, action]) => {
        const actionValidator = (actionTrigger: string) => {
          if (action.triggerType === "user" && !roles[actionTrigger]) {
            validated = false;

            if (options?.debug) {
              throw new Error(
                `Found role "${actionTrigger}" in action "${actionName}" that does not exist in procedure "${name}"`
              );
            }
          }

          if (!Object.keys(states).includes(action.newState)) {
            validated = false;

            if (options?.debug) {
              throw new Error(
                `Action "${actionName}" in state "${stateName}" in procedure ${
                  data.name
                } has newState "${
                  action.newState
                }" which was not found. \nValid options are:\n"${Object.keys(states).join(
                  `",\n"`
                )}"`
              );
            }
          }
        };

        //Fail if action isn't properly defined
        if (!action) {
          validated = false;

          if (options?.debug) {
            throw new Error(`Action ${actionName} on state ${stateName} failed validation`);
          }
          return validated;
        }

        if (typeof action.triggeredBy === "string") {
          actionValidator(action.triggeredBy);
        } else if (Array.isArray(action.triggeredBy)) {
          action.triggeredBy.forEach(actionValidator);
        } else if (action.triggeredBy === null) {
          //Do nothing
        } else {
          const exhaustive: never = action.triggeredBy;
          throw new Error(`Strange triggeredBy for ${actionName}: "${action.triggeredBy}"`);
        }
      });
    });

    //Fail if the starting state doesn't exist
    if (!Object.keys(states).includes(startingState)) {
      validated = false;

      if (options?.debug) {
        throw new Error(
          `startingState "${startingState}" not found. Valid states are ${JSON.stringify(
            Object.keys(states)
          )}`
        );
      }
    }

    //Fail if the starting action doesn't exist
    if (!Object.keys(states[startingState].on).includes(startingAction)) {
      validated = false;

      if (options?.debug) {
        throw new Error(
          `startingAction "${startingAction}" not found on startingState "${startingState}"`
        );
      }
    }

    //Fail if the starting action has no required sections
    const filtered = states[startingState].on[startingAction].requiredSections.filter(
      (sectionRequired) => sectionRequired
    );

    if (filtered.length === 0) {
      validated = false;

      if (options?.debug) {
        throw new Error(
          `startingAction "${startingAction}" in startingState "${startingState}" does not have any required sections`
        );
      }
    }

    return validated;
  }

  validate(options?: { debug?: boolean }): boolean {
    if (!this.isComplete()) {
      throw new Error(`Procedure failed completeness check`);
      return false;
    }

    const data = this.get();

    return ProcedureClass.validate(data, options);
  }

  static getFieldWidth(field: EventField): "full" | "regular" {
    switch (field.type) {
      case "short_text":
        return "regular";
      case "long_text":
        return "regular";
      case "date":
        return "regular";
      case "boolean":
        return "regular";
      case "number":
        return "regular";
      case "enum":
        return "regular";
      case "images":
        return "regular";
      case "documents":
        return "regular";
      case "plan_markup":
        return "regular";
      case "cost_impact":
        return "full";
      case "event_reference":
        return "regular";
      case "stakeholder_reference":
        return "regular";
      case "heading":
        return "regular";
      default:
        const exhaustiveCheck: never = field;
        throw new Error(`Unexpected field ${JSON.stringify(field)}`);
    }
  }

  get(): ProcedureModel {
    return {
      projectId: this.projectId,
      name: this.name,
      shorthand: this.shorthand,
      description: this.description,
      startingAction: this.startingAction,
      startingState: this.startingState,
      initiator: this.initiator,
      sections: this.sections,
      states: this.states,
      roles: this.roles,
      archived: this.archived,
    };
  }
}

export const procedureConverter: FirestoreDataConverter<ProcedureClass> = {
  fromFirestore(
    snapshot: QueryDocumentSnapshot<Partial<ProcedureModel>>,
    options: SnapshotOptions | undefined
  ): ProcedureClass {
    const data = snapshot.data();

    if (!ProcedureClass.validate(data, { debug: true })) {
      throw new Error(`Procedure failed validation`);
    }

    const instance = new ProcedureClass({
      description: data.description,
      initiator: data.initiator,
      name: data.name,
      projectId: data.projectId,
      roles: data.roles,
      sections: data.sections,
      shorthand: data.shorthand,
      startingAction: data.startingAction,
      startingState: data.startingState,
      states: data.states,
      archived: data.archived,
    });

    return instance;
  },
  toFirestore(
    modelObject: WithFieldValue<ProcedureClass>,
    options?: SetOptions
  ): PartialWithFieldValue<ProcedureModel> {
    if (modelObject instanceof ProcedureClass) {
      return modelObject.get();
    } else {
      return {
        projectId: modelObject.projectId,
        name: modelObject.name,
        shorthand: modelObject.shorthand,
        description: modelObject.description,
        startingAction: modelObject.startingAction,
        startingState: modelObject.startingState,
        initiator: modelObject.initiator,
        sections: modelObject.sections,
        states: modelObject.states,
        roles: modelObject.roles,
        archived: modelObject.archived,
      };
    }
  },
};

export default ProcedureClass;
