import * as fs from "firebase/firestore";

/**
 * Convert an array of firebase snapshots into a map of the snapshot data indexed by the snapshot's ID to avoid repeated
 * finds (improving performance). You probably shouldn't mix snapshot types.
 */
export function snapshotArrayToMap<T>(arr: fs.DocumentSnapshot<T>[]): {
  [r: string]: T | undefined;
} {
  const map: { [r: string]: T | undefined } = {};

  arr.forEach((snapshot) => (map[snapshot.id] = snapshot.data()));

  return map;
}
