import i18n, { StringMap, TFunctionKeys, TFunctionResult, TOptions } from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import english from "./en.json";
import lithuanian from "./lt.json";
import * as _ from "lodash";

declare module "react-i18next" {
  interface TFunction {
    // basic usage
    <
      TResult extends TFunctionResult = string,
      TKeys extends TFunctionKeys = string,
      TInterpolationMap extends object = StringMap
    >(
      key: TKeys | TKeys[],
      options?: TOptions<TInterpolationMap> | string
    ): string;
    // overloaded usage
    <
      TResult extends TFunctionResult = string,
      TKeys extends TFunctionKeys = string,
      TInterpolationMap extends object = StringMap
    >(
      key: TKeys | TKeys[],
      defaultValue?: string,
      options?: TOptions<TInterpolationMap> | string
    ): string;
  }
}

const resources = {
  en: {
    translation: english,
  },
  lt: {
    translation: lithuanian,
  },
};

export type LocaleCode = keyof typeof resources;

/** Translation key that will resolve for all languages. Eg "events.singleEvent.title" */
export type TranslationKey = keyof typeof english;

/** @see https://stackoverflow.com/questions/53807517/how-to-test-if-two-types-are-exactly-the-same */
type IfEquals<T, U, Y = unknown, N = never> = (<G>() => G extends T ? 1 : 2) extends <
  G
>() => G extends U ? 1 : 2
  ? Y
  : N;

type EnglishMissing = Omit<keyof typeof lithuanian, keyof typeof english>;
type LithuanianMissing = Omit<keyof typeof lithuanian, keyof typeof english>;

type EQ = IfEquals<typeof english, typeof lithuanian, "same", "different">;

/**
 * If the line below is causing a type error, that's because en.json and lt.json don't have an identical set of keys.
 * To resolve this problem go to scripts/misc and run ``ts-node compareLocaleKeys.ts``. If that gives you an error,
 * make sure "module" in tsconfig.json is set to "commonjs".
 *
 * Comment out the line below to disable this check for development purposes, but it shouldn't stay that way
 */
const sameKeysGuard: EQ = "same";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    returnObjects: false,
  });

export default i18n;
