//Libraries
import React, { useCallback, useContext, useMemo } from "react";
import * as fs from "firebase/firestore";

//Context
import { FirebaseContext } from "./firebaseContextProvider";

//Helpers
import { useCollection } from "react-firebase-hooks/firestore";

//Types
import db from "../backend/models";
import { NotificationModel } from "../backend/models/notification/model";

interface UnreadNotificationsContextProvider {
  notifications: [
    fs.QueryDocumentSnapshot<NotificationModel>[] | undefined,
    boolean,
    fs.FirestoreError | undefined
  ];
  unreadNotifications: number | "loading";
}

const UnreadNotificationsContext = React.createContext<UnreadNotificationsContextProvider>({
  notifications: [undefined, true, undefined],
  unreadNotifications: "loading",
});

function UnreadNotificationsProvider(props: { children: React.ReactNode }) {
  const firebaseContext = useContext(FirebaseContext);

  const ref = useMemo(
    () => (firebaseContext.userData ? db.notifications(firebaseContext?.userData?.id) : null),
    [firebaseContext?.userData]
  );

  const [notifications, notificationsLoading, notificationsError] = useCollection(
    ref ? fs.query(ref, fs.where("unread", "==", true)) : null
  );

  const unreadNotifications = useMemo(() => {
    if (notificationsLoading) {
      return "loading";
    }

    if (notificationsError) {
      return 0;
    }

    return notifications?.docs.length || 0;
  }, [notifications, notificationsLoading, notificationsError]);

  return (
    <UnreadNotificationsContext.Provider
      value={{
        notifications: [notifications?.docs, notificationsLoading, notificationsError],
        unreadNotifications,
      }}
    >
      {props.children}
    </UnreadNotificationsContext.Provider>
  );
}

export { UnreadNotificationsProvider, UnreadNotificationsContext };
