//React
import React, { useCallback, useContext, useMemo, useState } from "react";
import { Context } from "./contextProvider";
import { FirebaseContext } from "./firebaseContextProvider";
import { useCollection } from "react-firebase-hooks/firestore";
import * as fs from "firebase/firestore";
import db from "../backend/models";
import EventClass from "../backend/models/event/class";
import ProcedureClass from "../backend/models/procedure/class";
import { groupEventsByUrgency } from "../services/eventUrgency.service";
import { EventUrgency } from "../services/tables/eventsTable.service";

export type EventsByUrgency =
  | "loading"
  | { [r in EventUrgency]: fs.QueryDocumentSnapshot<EventClass>[] };

interface EventsContextResources {
  events: [
    fs.QueryDocumentSnapshot<EventClass>[] | undefined,
    boolean,
    fs.FirestoreError | undefined
  ];
  procedures: [
    fs.QueryDocumentSnapshot<ProcedureClass>[] | undefined,
    boolean,
    fs.FirestoreError | undefined
  ];
}

interface EventsContextProvider extends EventsContextResources {
  byProject: (projectId: string) => EventsContextResources;
  byUrgency: EventsByUrgency;
  byProjectAndUrgency: (projectId: string) => EventsByUrgency;
}

const EventsContext = React.createContext<EventsContextProvider>({
  events: [undefined, true, undefined],
  procedures: [undefined, true, undefined],
  byProject: () => ({
    events: [undefined, true, undefined],
    procedures: [undefined, true, undefined],
  }),
  byUrgency: "loading",
  byProjectAndUrgency: () => "loading",
});

function EventsProvider(props: { children: React.ReactNode }) {
  const firebaseContext = useContext(FirebaseContext);

  const [events, eventsLoading, eventsError] = useCollection(
    firebaseContext?.memberships
      ? fs.query(
          db.events,
          fs.where(
            "_ownerTeams",
            "array-contains-any",

            firebaseContext.memberships.size > 0
              ? firebaseContext?.memberships?.docs.map((doc) => {
                  return doc.data().teamId;
                })
              : ["???"]
          )
        )
      : null
  );

  const [procedures, proceduresLoading, proceduresError] = useCollection(
    firebaseContext?.memberships
      ? fs.query(
          db.procedures,
          fs.where(
            "projectId",
            "in",
            firebaseContext?.memberships?.docs.map((doc) => {
              return doc.data().projectId;
            }) || ["???"]
          )
        )
      : null
  );

  const [memberships, membershipsLoading, membershipsError] = useCollection(
    firebaseContext.userData?.id
      ? fs.query(
          db.teamMemberships,
          fs.where("userId", "==", firebaseContext.userData?.id),
          fs.where("status", "==", "active")
        )
      : null
  );

  /** Return just the resources for a specified project ID */
  const byProject = useCallback(
    (projectId: string): EventsContextResources => {
      return {
        events: [
          events?.docs.filter((obj) => obj.data().projectId === projectId),
          eventsLoading,
          eventsError,
        ],
        procedures: [
          procedures?.docs.filter((obj) => obj.data().projectId === projectId),
          proceduresLoading,
          proceduresError,
        ],
      };
    },
    [procedures, proceduresLoading, proceduresError, events, eventsLoading, eventsError]
  );

  const byUrgency: EventsByUrgency = useMemo(() => {
    if (eventsLoading || proceduresLoading || membershipsLoading || !firebaseContext.userData) {
      return "loading";
    }

    if (!events || !procedures || !memberships) {
      return {
        none: [],
        responseNeeded: [],
        urgentResponseNeeded: [],
      };
    }

    return groupEventsByUrgency(
      events.docs,
      procedures.docs,
      memberships.docs,
      firebaseContext.userData?.id
    );
  }, [
    procedures,
    proceduresLoading,
    proceduresError,
    events,
    eventsLoading,
    eventsError,
    memberships,
    membershipsLoading,
    membershipsError,
    firebaseContext.userData,
  ]);

  const byProjectAndUrgency = useCallback(
    (projectId: string): EventsByUrgency => {
      if (byUrgency === "loading") {
        return byUrgency;
      }

      return {
        none: byUrgency.none.filter((obj) => obj.data().projectId === projectId),
        responseNeeded: byUrgency.responseNeeded.filter(
          (obj) => obj.data().projectId === projectId
        ),
        urgentResponseNeeded: byUrgency.urgentResponseNeeded.filter(
          (obj) => obj.data().projectId === projectId
        ),
      };
    },
    [byUrgency]
  );

  return (
    <EventsContext.Provider
      value={{
        events: [events?.docs, eventsLoading, eventsError],
        procedures: [procedures?.docs, proceduresLoading, proceduresError],
        byProject,
        byUrgency,
        byProjectAndUrgency,
      }}
    >
      {props.children}
    </EventsContext.Provider>
  );
}

export { EventsProvider, EventsContext };
