//Firebase
import * as fs from "firebase/firestore";

//Types
import { procedureConverter } from "./models/procedure/class";
import { eventConverter } from "./models/event/class";
import { TeamModel } from "./models/team/model";
import { UserModel } from "./models/user/model";
import { TeamMembershipModel } from "./models/teamMembership/model";
import { ProjectEventCounterModel, ProjectModel } from "./models/project/model";
import modelPaths from "./models/modelList";
import { NotificationModel } from "./models/notification/model";
import { DraftModel } from "./models/draft/model";

/** The purpose of this generic is to make all values partial, and add undefined to all function return values */
export type ModelClass<T> = {
  [P in keyof T]: T[P] extends (...a: infer A) => infer G
    ? (...a: A) => G extends Promise<infer K> ? Promise<K | undefined> : Partial<G> | undefined
    : T[P] | undefined;
};
export type CompleteModel<T> = {
  [P in keyof T]: T[P] extends (...a: infer A) => infer G
    ? G extends Promise<infer K>
      ? (...a: A) => Promise<NonNullable<K>>
      : (...a: A) => NonNullable<G>
    : NonNullable<T[P]>;
};

// This helper function pipes your types through a firestore converter
const converter = <T>() => ({
  toFirestore: (data: fs.WithFieldValue<T>) => data,
  fromFirestore: (snap: fs.QueryDocumentSnapshot) => snap.data() as T,
});

// This helper function exposes a 'typed' version of firestore().collection(collectionPath)
// Pass it a collectionPath string as the path to the collection in firestore
// Pass it a type argument representing the 'type' (schema) of the docs in the collection
export const dataPoint = <T>(collectionFirstArg: string, ...collectionPath: string[]) =>
  fs
    .collection(fs.getFirestore(), collectionFirstArg, ...collectionPath)
    .withConverter(converter<T>());

export const collectionReference = <T>(
  collectionPath: string,
  converter: fs.FirestoreDataConverter<T>
) => fs.collection(fs.getFirestore(), collectionPath).withConverter(converter);

/**
 * **IMPORTANT** if you change any collection paths, you should also change them in functions/config.ts
 */
const db = {
  procedures: collectionReference(modelPaths.procedures, procedureConverter),
  users: dataPoint<UserModel>(modelPaths.users),
  teams: dataPoint<TeamModel>(modelPaths.teams),
  teamMemberships: dataPoint<TeamMembershipModel>(modelPaths.teamMemberships),
  projects: dataPoint<ProjectModel>(modelPaths.projects),
  events: collectionReference(modelPaths.events, eventConverter),
  eventCounters: (projectId: string) => {
    return dataPoint<ProjectEventCounterModel>(
      modelPaths.projects,
      projectId,
      modelPaths.projectsEventCounters
    );
  },
  notifications: (userId: string) => {
    return dataPoint<NotificationModel>(modelPaths.users, userId, modelPaths.notifications);
  },
  drafts: (userId: string) => dataPoint<DraftModel>(modelPaths.users, userId, modelPaths.drafts),
};

// export your helper
export { db };
export default db;
