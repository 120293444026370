import React, { useEffect, useRef, useState } from "react";
import "./loader.css";
import { withCancel } from "../../helpers/withCancel";

type LoaderProps = {
  delay?: number;
  className?: string;
  size?: number;
};

/**
 * **Loader Component**
 *
 * Displays a loader if
 *
 * @param {LoaderProps} props
 * @constructor
 */
export function Loader(props: LoaderProps) {
  //  const [ready, setReady] = useState(false);
  //
  //  useEffect(() => {
  //    const wait = new Promise((resolve, reject) => {
  //      setTimeout(() => setReady(true), props.delay || 0);
  //    });
  //
  //    const waitWithCancel = withCancel(wait);
  //
  //    return () => {
  //      waitWithCancel.cancel();
  //    };
  //  }, []);
  //
  //  if (!ready) return <></>;

  return (
    <div
      className={
        "w-full py-8 flex items-center justify-center " + props.className
      }
    >
      <svg
        className="spinner"
        width={props.size ? props.size + "px" : "30px"}
        height={props.size ? props.size + "px" : "30px"}
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="path"
          fill="none"
          strokeWidth="6"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        ></circle>
      </svg>
    </div>
  );
}
