//React
import React, { useState } from "react";
import { Context } from "./contextProvider";

export type RightSidebarMetadataItem = { label: string; data: string | string[] };

export type RightSidebarContent = {
  title: string;
  metadata?: RightSidebarMetadataItem[];
  content: React.ReactNode;
} | null;

interface ContextProvider {
  setRightSidebarContent: (content: RightSidebarContent) => void;
  rightSidebarContent: RightSidebarContent;
  setRightSidebarVisible: (visible: boolean) => void;
  rightSidebarVisible: boolean;
}

const RightSidebarContext = React.createContext<ContextProvider>({
  setRightSidebarContent: () => {},
  rightSidebarContent: null,
  setRightSidebarVisible: () => {},
  rightSidebarVisible: false,
});

function RightSidebarProvider(props: { children: React.ReactNode }) {
  const [rightSidebarVisible, setRightSidebarVisible] = useState<boolean>(true);
  const [rightSidebarContent, setRightSidebarContent] = useState<RightSidebarContent>(null);

  return (
    <RightSidebarContext.Provider
      value={{
        rightSidebarContent,
        setRightSidebarContent,
        rightSidebarVisible,
        setRightSidebarVisible,
      }}
    >
      {props.children}
    </RightSidebarContext.Provider>
  );
}

export { RightSidebarProvider, RightSidebarContext };
