//Libraries
import React, { useState } from "react";
import useOnClickOutside from "react-cool-onclickoutside";

//Components
import * as Icons from "heroicons-react";
import { Transition } from "@headlessui/react";
import { DropDownOption } from "./dropDown";

const styles: { [key: string]: string } = {
  default:
    "inline-flex items-center py-2 px-3  border border-gray-300 text-base rounded-md  leading-4 font-medium text-gray-700 " +
    "hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 " +
    "active:text-gray-800 transition duration-150 ease-in-out",
  danger:
    "py-2 px-3 bg-red-50 border border-red-300 rounded-md text-base leading-4 font-medium text-red-700 " +
    "hover:text-red-800 focus:outline-none focus:border-red-500 focus:shadow-outline-red active:bg-red-50 " +
    "active:text-red-800 transition duration-150 ease-in-out",
  inverted:
    "py-2 px-3 border border-gray-100 rounded-md text-base leading-4 font-medium text-gray-100 " +
    "hover:text-white focus:outline-none focus:border-gray-500 focus:shadow-outline-gray active:bg-gray-50 " +
    "active:text-gray-800 transition duration-150 ease-in-out",
  primary:
    "inline-flex items-center px-3 py-2 border border-transparent text-base leading-4 font-medium rounded-md text-white " +
    "bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:shadow-outline-primary " +
    "active:bg-primary-700 transition ease-in-out duration-150",
  disabled:
    "inline-flex items-center px-3 py-2 border border-transparent text-base leading-4 font-medium rounded-md text-white " +
    "bg-gray-300 cursor-not-allowed",
  dark:
    "inline-flex items-center px-3 py-2 border border-transparent text-base leading-4 font-medium rounded-md text-white " +
    "bg-gray-900 hover:bg-gray-800 focus:outline-none focus:border-gray-800 focus:shadow-outline-gray " +
    "active:bg-gray-800 transition ease-in-out duration-150",
};

export type ButtonTypes = "default" | "danger" | "inverted" | "primary" | "dark" | "disabled";

type ButtonDropDownOption = {
  key: string;
  label: string;
  onClick: () => void;
};

export interface ButtonProps {
  dropdownOptions?: ButtonDropDownOption[];
  hideDropdownChevron?: boolean;
  type?: ButtonTypes;
  onClick?: (event: React.MouseEvent) => void;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
}

/**
 * @param props
 * @param [props.type]
 * @param [props.onClick]
 * @param props.children
 * @param [props.className]
 * @param [props.disabled]
 * @param [props.loading]
 * @return {JSX.Element}
 */
const Button = (props: ButtonProps) => {
  const { type = "default", onClick, children, className } = props;

  const [collapsed, setCollapsed] = useState<boolean>(true);

  const ref = useOnClickOutside(() => setCollapsed(true));

  return (
    <div className="inline-block text-left noselect outline-none focus:outline-none focus:ring-0 focus:ring-opacity-0">
      <button
        onClick={(e) => {
          if (!props.disabled && props.type !== "disabled") {
            if (props.dropdownOptions) {
              setCollapsed((c) => !c);
            }

            if (props.onClick) {
              props.onClick(e);
            }
          }
        }}
        type="button"
        className={
          styles[props.disabled === true ? "disabled" : type] +
          " flex justify-center items-center " +
          className
        }
        disabled={props.disabled === true}
        style={{
          minHeight: 23.469 /* This is calculated from text so loading doesn't change size. Is there a better way? */,
        }}
      >
        {props.loading ? (
          <div className="flex justify-center w-full">
            <svg
              className="animate-spin h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          </div>
        ) : (
          <>
            {children}
            {props.dropdownOptions && !props.hideDropdownChevron && (
              <Icons.ChevronDown className="h-5 w-5" />
            )}
          </>
        )}
      </button>
      {props.dropdownOptions && (
        <Transition
          show={!collapsed}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div
            className="z-50 origin-top-left absolute left-0 mt-1 rounded-md border border-gray-100 shadow-sm"
            ref={ref}
          >
            <div className="rounded-md shadow-xs text-gray-700 bg-white overflow-hidden	">
              <div
                className={props.dropdownOptions.length >= 5 ? "overflow-y-scroll" : ""}
                style={{ maxHeight: 300 }}
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                {props.dropdownOptions.map((option: ButtonDropDownOption) => (
                  <div
                    key={option.key}
                    onClick={() => {
                      setCollapsed(true);
                      option.onClick();
                    }}
                    className={
                      "cursor-pointer block whitespace-no-wrap px-4 py-2 text-sidebar-item leading-5 hover:bg-gray-200 ease-in-out duration-150 focus:outline-none"
                    }
                    role="menuitem"
                  >
                    {option.label}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Transition>
      )}
    </div>
  );
};

export default Button;
