//Libraries
import * as fs from "firebase/firestore";
import * as cs from "firebase/storage";
import * as imageConversion from "image-conversion";
import Hash from "object-hash";

//Types
import { TableRow } from "../../components/table";
import { EImageType } from "image-conversion";
import {
  EventEvidence,
  EventEvidenceDocuments,
  EventEvidenceFile,
  EventEvidenceImages,
  EventEvidencePlanMarkup,
  EventSectionVersion,
} from "../../backend/models/event/model";
import EventClass from "../../backend/models/event/class";
import { ProcedureSection } from "../../backend/models/procedure/model";

export type UploadedData = {
  fieldId: string;
  fileId: string;
  src: string;
  description: string;
};

export function uploadImageField(
  documentId: string,
  field: EventEvidenceImages
): Promise<UploadedData>[] {
  return field.value.map(async (file, i) => {
    //Images we get might already be uploaded to cloud storage
    //If they are, we shouldn't upload them a second time
    if (
      file.src.startsWith(
        "https://firebasestorage.googleapis.com/v0/b/simpleconstructionsoftware.appspot.com"
      )
    ) {
      return {
        fileId: file.id,
        fieldId: field.id,
        src: file.src,
        description: file.description,
      };
    }

    const storageRef = cs.ref(cs.getStorage(), `${documentId}/${field.id}/${file.fileName}`);

    const fileObject = await imageConversion.dataURLtoFile(file.src);
    const compressed = await imageConversion.compress(fileObject, {
      type: EImageType.JPEG,
      quality: 90,
    });
    const uploadResult = await cs.uploadBytes(storageRef, compressed);
    const url = await cs.getDownloadURL(uploadResult.ref);

    return {
      fileId: file.id,
      fieldId: field.id,
      src: url,
      description: file.description,
    };
  });
}

export function uploadDocumentsField(
  documentId: string,
  field: EventEvidenceDocuments
): Promise<UploadedData>[] {
  return field.value.map(async (file, i) => {
    //Skip already uploaded documents
    if (
      file.src.startsWith(
        "https://firebasestorage.googleapis.com/v0/b/simpleconstructionsoftware.appspot.com"
      )
    ) {
      return {
        fileId: file.id,
        fieldId: field.id,
        src: file.src,
        description: file.description,
      };
    }

    const storageRef = cs.ref(cs.getStorage(), `${documentId}/${field.id}/${file.fileName}`);

    const fileObject = await imageConversion.dataURLtoFile(file.src);
    const uploadResult = await cs.uploadBytes(storageRef, fileObject);
    const url = await cs.getDownloadURL(uploadResult.ref);

    return {
      fileId: file.id,
      fieldId: field.id,
      src: url,
      description: file.description,
    };
  });
}

export function uploadPlanMarkupField(
  documentId: string,
  field: EventEvidencePlanMarkup
): Promise<UploadedData>[] {
  if (!field.value.src) {
    return [];
  }

  console.log(field);

  return [field.value].map(async (file, i) => {
    //Skip already uploaded images
    if (
      file.src.startsWith(
        "https://firebasestorage.googleapis.com/v0/b/simpleconstructionsoftware.appspot.com"
      )
    ) {
      return {
        fileId: `${documentId}_plan`,
        fieldId: field.id,
        src: file.src,
        description: file.description,
      };
    }
    const storageRef = cs.ref(cs.getStorage(), `${documentId}/${field.id}.jpg`);

    const fileObject = await imageConversion.dataURLtoFile(file.src);
    const compressed = await imageConversion.compress(fileObject, {
      type: EImageType.JPEG,
      quality: 90,
    });
    const uploadResult = await cs.uploadBytes(storageRef, compressed);
    const url = await cs.getDownloadURL(uploadResult.ref);

    console.log("DOWNLOAD URL " + url);

    return {
      fileId: `${documentId}_plan`,
      fieldId: field.id,
      src: url,
      description: file.description,
    };
  });
}

/** Naively a list of fields containing uploadable files from an array of procedure sections. Note that this method
 * does not filter for fields with files that have already been uploaded. */
export function getFieldsToUpload(procedureSections: ProcedureSection[]): EventEvidence[] {
  const fileFields: EventEvidence[] = [];
  procedureSections.forEach((section) => {
    section.fields.forEach((field) => {
      if (field.type === "images" || field.type === "documents" || field.type === "plan_markup") {
        fileFields.push(field);
      } else {
        const exhaustiveCheck: Extract<typeof field.type, EventEvidence["type"]> extends never
          ? any
          : never = field.type;
      }
    });
  });

  return fileFields;
}

export function uploadEvidenceField(
  eventId: string,
  field: EventEvidence
): Promise<UploadedData>[] {
  const filesToUpload: Promise<UploadedData>[] = [];
  if (field.type === "images") {
    filesToUpload.push(...uploadImageField(eventId, field));
  } else if (field.type === "documents") {
    filesToUpload.push(...uploadDocumentsField(eventId, field));
  } else if (field.type === "plan_markup") {
    filesToUpload.push(...uploadPlanMarkupField(eventId, field));
  } else {
    const exhaustiveCheck: never = field;
    throw new Error(`Unexpected field ${JSON.stringify(field)}`);
  }

  return filesToUpload;
}

export function uploadEvidence(eventId: string, fields: EventEvidence[]): Promise<UploadedData>[] {
  const filesToUpload: Promise<UploadedData>[] = [];
  fields.forEach((field) => {
    filesToUpload.push(...uploadEvidenceField(eventId, field));
  });

  return filesToUpload;
}

export function hashSectionVersion(version: Omit<EventSectionVersion, "hash">): string {
  return Hash(version);
}

export function eventSorter(
  a: TableRow<{ responseNeeded: boolean; responseTime: "none" | number }>,
  b: TableRow<{ responseNeeded: boolean; responseTime: "none" | number }>
) {
  const aResponseNeededValue = a.data.responseNeeded ? 10000000000 : 0;
  const bResponseNeededValue = b.data.responseNeeded ? 10000000000 : 0;

  const aOverdueBy = typeof a.data.responseTime === "string" ? -1000000000 : -a.data.responseTime;
  const bOverdueBy = typeof b.data.responseTime === "string" ? -1000000000 : -b.data.responseTime;

  return bResponseNeededValue - aResponseNeededValue + (bOverdueBy - aOverdueBy);
}

export function uploadedDataToFileEvidence(data: UploadedData): Required<EventEvidenceFile> {
  const decodedName = decodeURIComponent(data.src);
  const nameFull = decodedName.split("/").pop() || "";
  const nameWithoutQuery = nameFull.split("?");
  nameWithoutQuery.pop();

  const finalName = nameWithoutQuery.join("?");

  return {
    description: data.description,
    fileName: finalName,
    src: data.src,
    id: data.fileId,
  };
}
