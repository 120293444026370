//React
import React, { useState } from "react";

//Config
import siteConfig from "../config/siteConfig";

interface ContextProvider {
  getAPI: ((route: string) => Promise<any>) | (() => void);
  postAPI: ((route: string, data?: any, isPut?: boolean) => Promise<any>) | (() => void);
  refresh: boolean;
  doContextRefresh: () => void;
  showMenu: boolean;
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
  breadcrumbAliases: { [r: string]: string | undefined };
  breadcrumbAliasAdd: (r: [string, string]) => void;
  breadcrumbAliasRemove: (r: any) => void;
}

/**
 * @param defaultValue - Context defaults
 * @param defaultValue.getAPI - Make a GET request to the API
 * @param defaultValue.postAPI - Make a POST request to the API
 * @param defaultValue.refresh - Trigger rerenders in components consuming context
 * @param defaultValue.doContextRefresh - The handler for the refresh trigger
 * @param defaultValue.showMenu - Whether to display the sidebar menu on mobile
 * @param defaultValue.setShowMenu - Setter for mobile menu
 * @param defaultValue.rightSidebarContent - If this value is not null, the right sidebar will show content
 * @param defaultValue.setRightSidebarContent - Setter for right sidebar, takes in ReactNode
 * @param defaultValue.breadcrumbAliases - List of alias maps for breadcrumb topbar component (eg. accountproducts --> Account Products)
 * @param defaultValue.breadcrumbAliasAdd
 * @param defaultValue.breadcrumbAliasRemove
 *
 * @type {React.Context<ContextProvider>}
 */
const Context = React.createContext<ContextProvider>({
  getAPI: () => {},
  postAPI: () => {},
  refresh: false,
  doContextRefresh: () => {},
  showMenu: false,
  setShowMenu: () => {},
  breadcrumbAliases: siteConfig.defaultBreadcrumbAliases,
  breadcrumbAliasAdd: () => {},
  breadcrumbAliasRemove: () => {},
});

function ClientContextProvider(props: { children: React.ReactNode }) {
  const [refresh, setRefresh] = useState(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [breadcrumbAliases, setBreadcrumbAliases] = useState<{
    [r: string]: string | undefined;
  }>(siteConfig.defaultBreadcrumbAliases);

  const breadcrumbAliasAdd = ([key, value]: [string, string | undefined]) => {
    if (breadcrumbAliases[key]) {
      return;
    }

    setBreadcrumbAliases((prevState) => {
      return { ...prevState, [key]: value };
    });
  };
  const breadcrumbAliasRemove = (key: string) => {
    setBreadcrumbAliases((prevState) => {
      return { ...prevState, [key]: undefined };
    });
  };

  const doContextRefresh = async () => {
    await setRefresh(!refresh);
  };

  const postAPI = async (route: string, data: any, isPut?: boolean) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({});
      }, siteConfig.demoLoadingDelay);
    });
  };

  const getAPI = async (route: string) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({});
      }, siteConfig.demoLoadingDelay);
    });
  };
  return (
    <Context.Provider
      value={{
        postAPI,
        getAPI,
        refresh,
        doContextRefresh,
        showMenu,
        setShowMenu,
        breadcrumbAliases,
        breadcrumbAliasAdd,
        breadcrumbAliasRemove,
      }}
    >
      {props.children}
    </Context.Provider>
  );
}

export { ClientContextProvider, Context };
