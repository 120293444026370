const siteConfig = {
  /* How long to do loading animation in the demo before displaying the demo content */
  demoLoadingDelay: 500,

  /* The breadcrumb takes slugs from the url. Object keys here will replace specific slugs with nicer text */
  defaultBreadcrumbAliases: {
    projects: "Projects",
    createproject: "Create new project",
    create: "Create",
    addstakeholders: "Add new stakeholders",
    addstakeholder: "Add a new stakeholder",
    addteam: "Add a new team",
  },
};

export default siteConfig;
