//Libraries
import React from "react";
import { useTranslation } from "react-i18next";
import { getAuth, signOut } from "firebase/auth";
import Button from "../../components/button";

export type CrashScreenProps = {
  title: string;
  subtitle: string;
};

function CrashScreen(props: CrashScreenProps) {
  const { t } = useTranslation();

  return (
    <div className="bg-white flex items-center justify-center min-h-screen">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <p className="mt-1 text-4xl font-medium text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            {props.title}
          </p>
          <p className="max-w-2xl mt-5 mx-auto text-xl text-gray-500">{props.subtitle}</p>
          <div className="space-x-2 mt-4">
            <Button type="primary" className="text-lg" onClick={() => window.location.reload()}>
              Reload
            </Button>
            <Button type="primary" className="text-lg" onClick={() => signOut(getAuth())}>
              Log Out
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CrashScreen;
