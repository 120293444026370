import React, { useState } from "react";
import CrashScreen from "./crashScreen";
import { withTranslation } from "react-i18next";
import { i18n } from "i18next";

const PageHasBeenForceRefreshed = "page-has-been-force-refreshed";

const retryPageLoading = () => {
  const pageHasAlreadyBeenForceRefreshed = JSON.parse(
    window.localStorage.getItem(PageHasBeenForceRefreshed) || "false"
  ) as boolean;

  if (!pageHasAlreadyBeenForceRefreshed) {
    window.localStorage.setItem(PageHasBeenForceRefreshed, "true");
    return window.location.reload();
  } else {
    window.localStorage.setItem(PageHasBeenForceRefreshed, "false");
  }
};

interface ErrorBoundaryProps {
  children: React.ReactNode;
  i18n: i18n;
  /** Default: true */
  disableAutoReload?: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, { errorName: string | null }> {
  constructor(props: Readonly<ErrorBoundaryProps>) {
    super(props);
    this.state = { errorName: null };
  }

  componentDidCatch(error: unknown, info: unknown) {
    const err = error as any;

    if (err.name === "ChunkLoadError" && !this.props.disableAutoReload) {
      retryPageLoading();
    }

    this.setState({ errorName: typeof err === "object" && err ? err.name : "Unknown error" });
    console.log(error, info);
  }

  render() {
    if (this.state.errorName) {
      return <CrashScreen title={this.props.i18n.t("Something went wrong")} subtitle={""} />;
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
