import * as fs from "firebase/firestore";
import EventClass from "../backend/models/event/class";
import ProcedureClass from "../backend/models/procedure/class";
import { snapshotArrayToMap } from "./firestore.service";
import { EventUrgency } from "./tables/eventsTable.service";
import { TeamModel } from "../backend/models/team/model";
import { TeamMembershipModel } from "../backend/models/teamMembership/model";

/**
 * Categorise an array of events into urgency of "none", "responseNeeded" or "urgentResponseNeeded"
 * @throws {Error} if any event's procedureId is not in the procedures array.
 */
export function groupEventsByUrgency(
  events: fs.DocumentSnapshot<EventClass>[],
  procedures: fs.DocumentSnapshot<ProcedureClass>[],
  memberships: fs.DocumentSnapshot<TeamMembershipModel>[],
  userId: string
): { [r in EventUrgency]: fs.QueryDocumentSnapshot<EventClass>[] } {
  const procedureMap = snapshotArrayToMap(procedures);

  const store: { [r in EventUrgency]: fs.QueryDocumentSnapshot<EventClass>[] } = {
    none: [],
    responseNeeded: [],
    urgentResponseNeeded: [],
  };

  events.forEach((event) => {
    if (!event.exists()) {
      return;
    }

    const eventData = event.data();

    console.log(procedureMap);
    const procedure = procedureMap[eventData.procedureId || "???"];
    if (!eventData.procedureId || !procedure) {
      throw new Error(`Procedure ${eventData.procedureId} not found`);
    }

    const responseTime = eventData.getRemainingResponseTime(procedure);

    const actionOwners = eventData.getActionOwners(procedure, {
      onlyRequiredActions: true,
    });

    const membership = memberships.find(
      (membership) =>
        membership.data()?.projectId === eventData.projectId &&
        membership.data()?.status === "active"
    );

    if (!membership || !membership.exists()) {
      throw new Error(`Membership for project ${eventData.projectId} not found`);
    }

    const responseNeeded = Object.values(actionOwners).find(
      (owners) =>
        owners.userIds.includes(userId) ||
        owners.teamIds.includes(membership.data().teamId || "???")
    );

    console.log(event.id);
    console.log(responseTime);
    console.log(responseNeeded);

    if (responseNeeded && typeof responseTime === "number" && responseTime < 86400000) {
      store.urgentResponseNeeded.push(event);
    } else if (responseNeeded) {
      store.responseNeeded.push(event);
    } else {
      store.none.push(event);
    }
  });

  return store;
}
