//Libraries
import * as React from "react";
import { getAuth, User as FirebaseUser } from "firebase/auth";
import * as fs from "firebase/firestore";
import * as fa from "firebase/auth";
import i18next from "i18next";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { useEffect, useState } from "react";

//Components
import { Loader } from "../components/loader";

//Types
import db from "../backend/models";
import { TeamModel } from "../backend/models/team/model";
import { UserModel } from "../backend/models/user/model";
import { TeamMembershipModel } from "../backend/models/teamMembership/model";
import { ProjectModel } from "../backend/models/project/model";

type ContextState = {
  user: FirebaseUser | null | undefined;
  userData: fs.DocumentSnapshot<UserModel> | undefined;
  projects: fs.QuerySnapshot<ProjectModel> | undefined;
  memberships: fs.QuerySnapshot<TeamMembershipModel> | undefined;
  teams: fs.QuerySnapshot<TeamModel> | undefined;
  getMembership: (
    projectId: string | undefined
  ) => fs.DocumentSnapshot<TeamMembershipModel> | undefined;
  getProject: (projectId: string | undefined) => fs.DocumentSnapshot<ProjectModel> | undefined;
};

export const FirebaseContext = React.createContext<ContextState>({
  user: undefined,
  userData: undefined,
  projects: undefined,
  memberships: undefined,
  teams: undefined,
  getMembership: () => undefined,
  getProject: () => undefined,
});

const FirebaseProvider: React.FC = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<FirebaseUser | null>();
  const [userLoaded, setUserLoaded] = useState<boolean>(true);

  useEffect(() => {
    console.log("Registering firebase auth listener");

    const unsubscribe = fa.onAuthStateChanged(
      fa.getAuth(),
      function (user) {
        console.log(user);
        if (user?.displayName) {
          setCurrentUser(user);
        } else {
          setUserLoaded(false);
          setTimeout(() => { setUserLoaded(true); setCurrentUser(user); }, 3000);
        }
      },
      (error) => console.error(error),
      () => console.warn("Firebase listener unhooked")
    );
    return unsubscribe;
  }, []);

  console.log(currentUser);
  const [userData, userDataLoading] = useDocument(
    getAuth().currentUser
      ? fs.doc(
        db.users,
        getAuth().currentUser?.displayName
          ? getAuth().currentUser?.displayName || ""
          : getAuth().currentUser?.uid
      )
      : null
  );
  console.log(userData?.data());

  const [projects, projectsLoading] = useCollection(
    userData && userData.exists()
      ? fs.query(
        db.projects,
        fs.where(
          fs.documentId(),
          "in",
          userData.data().projects || ["???"]
        )
      )
      : null
  );
  console.log(projects?.docs);

  console.log(currentUser?.displayName);
  const [memberships, membershipsLoading] = useCollection(
    currentUser
      ? fs.query(
        db.teamMemberships,
        fs.where(
          "userId",
          "==",
          currentUser.displayName ? currentUser.displayName : currentUser.uid
        ),
        fs.where("status", "==", "active")
      )
      : null
  );
  console.log(memberships?.docs);
  console.log(memberships?.size);
  console.log(membershipsLoading);

  const [teams, teamsLoading] = useCollection(
    memberships && memberships.size > 0
      ? fs.query(
        db.teams,
        fs.where(
          fs.documentId(),
          "in",
          memberships.docs.map((membership) => membership.data().teamId || ["???"])
        )
      )
      : null
  );
  console.log(teams?.docs);

  const getMembership = (projectId: string | undefined) => {
    if (!projectId) {
      return;
    }

    return memberships?.docs.find((membership) => membership.data().projectId === projectId);
  };

  const getProject = (projectId: string | undefined) => {
    if (!projectId || !projects) {
      return;
    }

    return projects.docs.find((project) => project.id === projectId);
  };

  const value = {
    user: currentUser,
    projects,
    memberships,
    teams,
    userData,
    getMembership,
    getProject,
  };

  React.useEffect(() => {
    if (userData && userData.exists()) {
      const lng = userData.data().language;

      i18next.changeLanguage(lng);
    }
  }, [userData]);

  return (
    <FirebaseContext.Provider value={value}>
      {!userDataLoading && !membershipsLoading && !teamsLoading && !projectsLoading && userLoaded ? (
        children
      ) : (
        <div className="w-full h-screen flex items-center justify-center">
          <Loader />
        </div>
      )}
    </FirebaseContext.Provider>
  );
};

export default FirebaseProvider;
