//IMPORTANT: Keep this module pure - no imports. This is referenced by cloud functions, import bloat kills performance

/**
 * **If you change any of these, you MUST update firestore.rules in the project root as well**
 * */
const modelPaths: {
  procedures: "v2_procedures";
  users: "v2_users";
  teams: "teams";
  teamMemberships: "teamMemberships";
  projects: "projects";
  events: "v3_events";
  projectsEventCounters: "eventCounters";
  notifications: "notifications";
  drafts: "drafts";
} = {
  procedures: "v2_procedures",
  users: "v2_users",
  teams: "teams",
  teamMemberships: "teamMemberships",
  projects: "projects",
  events: "v3_events",
  projectsEventCounters: "eventCounters",
  notifications: "notifications",
  drafts: "drafts",
};

export default modelPaths;
