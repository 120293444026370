import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { firebaseConfig } from "./config";

//ENABLING THIS WILL CAUSE TESTS TO FAIL AND PROD DATABASE INTERACTED WITH, BE CAREFUL
const DISABLE_EMULATORS = false;

const firebaseInstance =
  process.env.NODE_ENV === "production" ||
  process.env.REACT_APP_FIREBASE_INSTANCE === "live" ||
  DISABLE_EMULATORS
    ? "live"
    : "emulators";

const app = firebase.initializeApp(firebaseConfig);

if (firebaseInstance === "emulators") {
  console.log("App running in development, connecting emulators...");
  const db = getFirestore(app);
  //@ts-ignore
  if (typeof window !== "undefined" && window.Cypress) {
    firebase.firestore().settings({ experimentalForceLongPolling: true });
  }
  connectFirestoreEmulator(db, "localhost", 5002);

  const auth = getAuth(app);
  connectAuthEmulator(auth, `http://localhost:9099`);

  const storage = getStorage();
  connectStorageEmulator(storage, "localhost", 9199);
  console.log("Emulators connected successfully");
}

export { firebaseInstance, app };
